
import {
    Emitter,
    PropType,
    TableLimit,
    computed,
    defineComponent,
    inject,
    ref
} from 'vue'
import betBox from './BetBox.vue'
import {
    ACT,
    BET,
    BET_TYPE,
    BET_TYPES,
    EventBuss,
    OtherApiParams
} from '@/types/global'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import { AppActions, AppMutation } from '@/store/types'
export default defineComponent({
    emits: ['on-update-temp-bet', 'session-expired'],
    components: {
        'bet-box': betBox
    },
    props: {
        allowBet: {
            type: Boolean as PropType<boolean>,
            default: () => true
        },
        gameType: {
            type: Number as PropType<number>,
            default: () => 1
        },
        redConfirmedBet: {
            required: true,
            type: Number as PropType<number>
        },
        blueConfirmedBet: {
            required: true,
            type: Number as PropType<number>
        },
        greenConfirmedBet: {
            required: true,
            type: Number as PropType<number>
        },
        redPairConfirmedBet: {
            required: true,
            type: Number as PropType<number>
        },
        bluePairConfirmedBet: {
            required: true,
            type: Number as PropType<number>
        },
        super6ConfirmedBet: {
            type: Number as PropType<number>,
            default: () => 0
        },
        xian: {
            required: true,
            type: Number as PropType<number>
        },
        tableNo: {
            required: true,
            type: Number as PropType<number>
        },
        winresult: {
            type: Array as PropType<number[]>,
            default: () => []
        }
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const { t } = useI18n()
        const redTemp = ref<number>(0)
        const blueTemp = ref<number>(0)
        const greenTemp = ref<number>(0)
        const redPairTemp = ref<number>(0)
        const bluePairTemp = ref<number>(0)

        const blueConfirmedTemp = ref<number>(0)
        const redConfirmedTemp = ref<number>(0)
        const greenConfirmedTemp = ref<number>(0)
        const bluePairConfirmedTemp = ref<number>(0)
        const redPairConfirmedTemp = ref<number>(0)

        const toastMessage = ref<{ id: number | boolean; message: string }[]>(
            []
        )

        const onPlaceBet = (e: BET_TYPES) => {
            if (!props.allowBet) {
                showErrorMessage(t('prompt.nobetallowed'))
                return
            }
            if (currentChipIndex.value === -1) {
                showErrorMessage(t('prompt.selectachip'))
                return
            }

            let _betType = -1

            if (e === 'BANKER') _betType = BET_TYPE.BANKER
            else if (e === 'PLAYER') _betType = BET_TYPE.PLAYER
            else if (e === 'TIE') _betType = BET_TYPE.TIE
            else if (e === 'DT_TIE') _betType = BET_TYPE.DT_TIE
            else if (e === 'DRAGON') _betType = BET_TYPE.DRAGON
            else if (e === 'TIGER') _betType = BET_TYPE.TIGER
            else if (e === 'BANKER_PAIR') _betType = BET_TYPE.BANKER_PAIR
            else if (e === 'PLAYER_PAIR') _betType = BET_TYPE.PLAYER_PAIR

            const messageCode = isBetOk(_betType, chipValue.value)

            switch (messageCode) {
                case BET.CODE.NO_BET_AREA_SELECTED:
                    showErrorMessage(t(BET.MESSAGE.NO_BET_AREA_SELECTED))
                    break
                case BET.CODE.INSUFICIENT_BALANCE:
                    showErrorMessage(t(BET.MESSAGE.INSUFICIENT_BALANCE))
                    break
                case BET.CODE.ABOVE_MAXIMUM:
                    showErrorMessage(t(BET.MESSAGE.ABOVE_MAXIMUM))
                    break
                case BET.CODE.OK:
                    audioPlayer.Play(SOUNDKEY.BET_PLACE)
                    break
                default:
                    break
            }

            trigerIfHasTempBet()
        }

        const isBetOk = (_betType: number, amount: number) => {
            // get the total temp bet
            const _totalTempBet =
                redTemp.value +
                blueTemp.value +
                greenTemp.value +
                +redPairTemp.value +
                bluePairTemp.value +
                amount
            // check if userpoint is enough
            if (_totalTempBet > coin.value) {
                fillInBet(_betType)
                return BET.CODE.INSUFICIENT_BALANCE
            } else {
                let tempBet = 0
                let max = limit.value.Max ?? 0

                let confirmed = 0
                if (
                    _betType === BET_TYPE.BANKER ||
                    _betType === BET_TYPE.DRAGON
                ) {
                    tempBet = redTemp.value
                    confirmed = props.redConfirmedBet
                } else if (
                    _betType === BET_TYPE.PLAYER ||
                    _betType === BET_TYPE.TIGER
                ) {
                    tempBet = blueTemp.value
                    confirmed = props.blueConfirmedBet
                } else if (_betType === BET_TYPE.BANKER_PAIR) {
                    tempBet = redPairTemp.value + bluePairTemp.value
                    confirmed =
                        props.redPairConfirmedBet + props.bluePairConfirmedBet
                    max = limit.value.PairMaxBet || 0
                } else if (_betType === BET_TYPE.PLAYER_PAIR) {
                    tempBet = bluePairTemp.value + redPairTemp.value
                    confirmed =
                        props.bluePairConfirmedBet + props.redPairConfirmedBet
                    max = limit.value.PairMaxBet || 0
                } else {
                    tempBet = greenTemp.value
                    max = limit.value.TieMaxBet ?? 0
                    confirmed = props.greenConfirmedBet
                }

                if (isAboveMaximum(_betType, tempBet, confirmed, amount, max)) {
                    return BET.CODE.ABOVE_MAXIMUM
                }
            }

            return BET.CODE.OK
        }

        const isAboveMaximum = (
            betType: number,
            tempBet: number,
            confirmed: number,
            amount: number,
            max: number
        ): boolean => {
            const totalTemp = tempBet + amount + confirmed
            let amt = amount
            let result = false

            if (betType === BET_TYPE.SUPER_6) {
                if (max < totalTemp) {
                    amt = max - tempBet
                    result = true
                }
            } else if (
                betType === BET_TYPE.TIE ||
                betType === BET_TYPE.DT_TIE
            ) {
                if (max < totalTemp) {
                    amt = max - tempBet
                    result = true
                }
            } else if (
                betType === BET_TYPE.PLAYER_PAIR ||
                betType === BET_TYPE.BANKER_PAIR
            ) {
                if (max < totalTemp) {
                    amt = max - totalTemp
                    if (amt < 0) {
                        amt = max - (confirmed + tempBet)
                    }
                    result = true
                }
            } else {
                let allRedTotal = redTemp.value + props.redConfirmedBet
                let allBlueTotal = blueTemp.value + props.blueConfirmedBet
                let difference = 0

                if (
                    betType === BET_TYPE.BANKER ||
                    betType === BET_TYPE.DRAGON
                ) {
                    // red
                    allRedTotal += amount
                } else if (
                    betType === BET_TYPE.PLAYER ||
                    betType === BET_TYPE.TIGER
                ) {
                    // blue
                    allBlueTotal += amount
                }
                if (allRedTotal > allBlueTotal) {
                    difference = allRedTotal - allBlueTotal
                } else {
                    difference = allBlueTotal - allRedTotal
                }

                if (difference > max) {
                    const fillAmount = max - difference
                    if (fillAmount < 0) {
                        applyTempBet(betType, amount - Math.abs(fillAmount))
                    }
                    return true
                }
            }

            applyTempBet(betType, amt)
            return result
        }

        const trigerIfHasTempBet = () => {
            const hasTempBet =
                redTemp.value +
                    blueTemp.value +
                    greenTemp.value +
                    redPairTemp.value +
                    bluePairTemp.value >
                0
            emit('on-update-temp-bet', hasTempBet)
        }

        const fillInBet = (betType: number) => {
            // fill in bet code
            const totalTempbet =
                redTemp.value +
                blueTemp.value +
                greenTemp.value +
                redPairTemp.value +
                bluePairTemp.value
            const tempBet = coin.value - totalTempbet
            applyTempBet(betType, tempBet)
        }

        const applyTempBet = (betType: number, amount: number) => {
            if (betType === BET_TYPE.BANKER || betType === BET_TYPE.DRAGON) {
                redTemp.value += amount
            } else if (
                betType === BET_TYPE.PLAYER ||
                betType === BET_TYPE.TIGER
            ) {
                blueTemp.value += amount
            } else if (
                betType === BET_TYPE.TIE ||
                betType === BET_TYPE.DT_TIE
            ) {
                greenTemp.value += amount
            } else if (betType === BET_TYPE.PLAYER_PAIR) {
                bluePairTemp.value += amount
            } else if (betType === BET_TYPE.BANKER_PAIR) {
                redPairTemp.value += amount
            }
        }

        const showErrorMessage = (_message: string) => {
            // emitter.emit(EventBuss.TOAST_IN_GAME_MESSAGE, {
            //     color: 100,
            //     content: _message
            // })

            const _t = {
                id: setTimeout(() => {
                    const _tt = toastMessage.value.shift()
                    if (_tt && typeof _tt.id === 'number') {
                        clearTimeout(_tt.id)
                    }
                }, 1500),
                message: _message
            }
            toastMessage.value.push(_t)
        }

        const isValidBet = (
            bet: number,
            confirmedBet: number,
            min: number
        ): boolean => {
            return bet + confirmedBet >= min
        }

        const isNotGreaterThanMaxBet = (
            bet: number,
            confirmedBet: number,
            max: number
        ): boolean => {
            return bet + confirmedBet <= max
        }

        const resetBet = () => {
            redTemp.value = 0
            blueTemp.value = 0
            greenTemp.value = 0
            bluePairTemp.value = 0
            redPairTemp.value = 0

            redConfirmedTemp.value = 0
            blueConfirmedTemp.value = 0
            greenConfirmedTemp.value = 0
            redPairConfirmedTemp.value = 0
            bluePairConfirmedTemp.value = 0

            trigerIfHasTempBet()
        }

        const confirmBet = () => {
            if (!props.allowBet) return

            const totalTempBet =
                redTemp.value +
                greenTemp.value +
                redPairTemp.value +
                blueTemp.value +
                bluePairTemp.value

            // check user point if less than the total temp bet
            if (totalTempBet > coin.value) {
                showErrorMessage(t(BET.MESSAGE.INSUFICIENT_BALANCE))
                resetBet()
            } else {
                // check limit
                // red bet
                if (redTemp.value > 0) {
                    if (
                        !isValidBet(
                            redTemp.value,
                            props.redConfirmedBet,
                            limit.value.Min || 0
                        )
                    ) {
                        showErrorMessage(t(BET.MESSAGE.BELOW_MINIMUM))
                        return
                    }
                }

                // blue bet
                if (blueTemp.value > 0) {
                    if (
                        !isValidBet(
                            blueTemp.value,
                            props.blueConfirmedBet,
                            limit.value.Min || 0
                        )
                    ) {
                        showErrorMessage(t(BET.MESSAGE.BELOW_MINIMUM))
                        return
                    }
                }

                // green
                if (greenTemp.value > 0) {
                    if (
                        !isValidBet(
                            greenTemp.value,
                            props.greenConfirmedBet,
                            limit.value.TieMinBet || 0
                        )
                    ) {
                        showErrorMessage(t(BET.MESSAGE.BELOW_MINIMUM))
                        return
                    }

                    if (
                        !isNotGreaterThanMaxBet(
                            greenTemp.value,
                            props.greenConfirmedBet,
                            limit.value.TieMaxBet || 0
                        )
                    ) {
                        showErrorMessage(t(BET.MESSAGE.ABOVE_MAXIMUM))
                        return
                    }
                }

                // red pair
                if (redPairTemp.value > 0) {
                    if (
                        !isValidBet(
                            redPairTemp.value,
                            props.redPairConfirmedBet,
                            limit.value.PairMinBet || 0
                        )
                    ) {
                        showErrorMessage(t(BET.MESSAGE.BELOW_MINIMUM))
                        return
                    }

                    if (
                        !isNotGreaterThanMaxBet(
                            redPairTemp.value,
                            props.redPairConfirmedBet,
                            limit.value.PairMaxBet || 0
                        )
                    ) {
                        showErrorMessage(t(BET.MESSAGE.ABOVE_MAXIMUM))
                        return
                    }
                }

                // blue pair
                if (bluePairTemp.value > 0) {
                    if (
                        !isValidBet(
                            bluePairTemp.value,
                            props.bluePairConfirmedBet,
                            limit.value.PairMinBet || 0
                        )
                    ) {
                        showErrorMessage(t(BET.MESSAGE.BELOW_MINIMUM))
                        return
                    }

                    if (
                        !isNotGreaterThanMaxBet(
                            bluePairTemp.value,
                            props.bluePairConfirmedBet,
                            limit.value.PairMaxBet || 0
                        )
                    ) {
                        showErrorMessage(t(BET.MESSAGE.ABOVE_MAXIMUM))
                        return
                    }
                }

                // check super6

                // check min and max of player and banker
                const allRedTotal = redTemp.value + props.redConfirmedBet
                const allBlueTotal = blueTemp.value + props.blueConfirmedBet
                let diffrence = 0
                if (allRedTotal > allBlueTotal) {
                    diffrence = allRedTotal - allBlueTotal
                } else {
                    diffrence = allBlueTotal - allRedTotal
                }

                if (diffrence > (limit.value.Max || 0)) {
                    showErrorMessage(t(BET.MESSAGE.ABOVE_MAXIMUM))
                    return
                }

                const model: any = {
                    ...OtherApiParams,

                    xian: props.xian,
                    username: user.value,
                    sessionID: token.value,
                    desk: props.tableNo
                }

                if (props.gameType === 1) {
                    model.act = ACT.DO_BAC_BET
                    if (redTemp.value > 0) model.y_z = redTemp.value
                    if (blueTemp.value > 0) model.y_x = blueTemp.value
                    if (bluePairTemp.value > 0) model.y_xd = bluePairTemp.value
                    if (redPairTemp.value > 0) model.y_zd = redPairTemp.value
                    if (greenTemp.value > 0) model.y_h = greenTemp.value
                } else if (props.gameType === 2) {
                    model.act = ACT.DO_DT_BET
                    if (redTemp.value > 0) model.long = redTemp.value
                    if (blueTemp.value > 0) model.hu = blueTemp.value
                    if (greenTemp.value > 0) model.he = greenTemp.value
                }

                store.commit(AppMutation.SHOW_LOADING)
                store
                    .dispatch(AppActions.FETCH_DATA, model)
                    .then((_data: string) => {
                        store.commit(AppMutation.HIDE_LOADING)
                        if (_data.includes('err=')) {
                            emit('session-expired')
                        } else {
                            const arrInfo = _data.split('#')
                            if (_data.includes('xzok')) {
                                store.commit(
                                    AppMutation.SET_COIN,
                                    parseInt(arrInfo[1])
                                )
                                redConfirmedTemp.value =
                                    props.redConfirmedBet + redTemp.value
                                blueConfirmedTemp.value =
                                    props.blueConfirmedBet + blueTemp.value
                                greenConfirmedTemp.value =
                                    props.greenConfirmedBet + greenTemp.value
                                redPairConfirmedTemp.value =
                                    props.redPairConfirmedBet +
                                    redPairTemp.value
                                bluePairConfirmedTemp.value =
                                    props.bluePairConfirmedBet +
                                    bluePairTemp.value
                                audioPlayer.Play(SOUNDKEY.BET_SUCCESS)
                                showErrorMessage(t('prompt.betsuccess'))

                                resetExclude()
                            } else {
                                showErrorMessage(arrInfo[1])
                                resetBet()
                            }
                        }
                    })
                    .catch(() => {
                        store.commit(AppMutation.HIDE_LOADING)
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            t('prompt.networkerror')
                        )
                        resetBet()
                    })
            }
        }

        const resetExclude = () => {
            redTemp.value = 0
            blueTemp.value = 0
            greenTemp.value = 0
            bluePairTemp.value = 0
            redPairTemp.value = 0

            trigerIfHasTempBet()
        }

        const redFinal = computed(() => {
            let _amt = 0
            if (props.redConfirmedBet >= redConfirmedTemp.value) {
                _amt = props.redConfirmedBet
            } else {
                _amt = redConfirmedTemp.value
            }
            return _amt
        })
        const blueFinal = computed(() => {
            let _amt = 0
            if (props.blueConfirmedBet >= blueConfirmedTemp.value) {
                _amt = props.blueConfirmedBet
            } else {
                _amt = blueConfirmedTemp.value
            }
            return _amt
        })

        const greenFinal = computed(() => {
            let _amt = 0
            if (props.greenConfirmedBet >= greenConfirmedTemp.value) {
                _amt = props.greenConfirmedBet
            } else {
                _amt = greenConfirmedTemp.value
            }
            return _amt
        })

        const redPairFinal = computed(() => {
            let _amt = 0
            if (props.redPairConfirmedBet >= redPairConfirmedTemp.value) {
                _amt = props.redPairConfirmedBet
            } else {
                _amt = redPairConfirmedTemp.value
            }
            return _amt
        })

        const bluePairFinal = computed(() => {
            let _amt = 0
            if (props.bluePairConfirmedBet >= bluePairConfirmedTemp.value) {
                _amt = props.bluePairConfirmedBet
            } else {
                _amt = bluePairConfirmedTemp.value
            }
            return _amt
        })

        const chipValue = computed(() => {
            let _val = 0

            if (chips.value.length < 5) return _val

            if (currentChipIndex.value === 5) return customChipValue.value

            return chips.value[currentChipIndex.value]
        })

        const currentChipIndex = computed(
            (): number => store.getters['currentChipIndex']
        )
        const limit = computed((): TableLimit => store.getters['limit'])
        const coin = computed((): number => store.getters['coin'])
        const chips = computed((): number[] => store.getters['chips'])
        const customChipValue = computed(() => store.getters['customChipValue'])
        const user = computed(() => store.getters['user'])
        const token = computed(() => store.getters['token'])

        return {
            onPlaceBet,
            redTemp,
            blueTemp,
            greenTemp,
            redPairTemp,
            bluePairTemp,
            redFinal,
            blueFinal,
            greenFinal,
            redPairFinal,
            bluePairFinal,
            toastMessage,
            resetBet,
            confirmBet,
            showErrorMessage,
            t
        }
    }
})
