import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63f09c5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chips-control-roots" }
const _hoisted_2 = ["onClick", "label"]
const _hoisted_3 = ["label"]
const _hoisted_4 = {
  key: 0,
  class: "label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_setting = _resolveComponent("icon-setting")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chips, (i, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass({ active: _ctx.activeChip === index })
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["chip", [`_${index}`]]),
          onClick: ($event: any) => (_ctx.selectChips(index)),
          label: _ctx.toShortValue(i)
        }, null, 10, _hoisted_2)
      ], 2))
    }), 128)),
    _createElementVNode("div", {
      class: _normalizeClass({ active: _ctx.activeChip === 5 })
    }, [
      _createElementVNode("div", {
        class: "chip _5",
        label: _ctx.customChipValue !== 0 ? _ctx.customChipValue : '',
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectCustomChip && _ctx.selectCustomChip(...args)))
      }, [
        (_ctx.customChipValue === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.t('game.chipl')), 1))
          : _createCommentVNode("", true)
      ], 8, _hoisted_3)
    ], 2),
    _createElementVNode("div", {
      class: "chip-custom-button",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showChipsDialog && _ctx.showChipsDialog(...args)))
    }, [
      _createVNode(_component_icon_setting)
    ])
  ]))
}