import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, TransitionGroup as _TransitionGroup, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45e0aad2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bet-group-root" }
const _hoisted_2 = {
  key: 0,
  class: "bet-group v-direction"
}
const _hoisted_3 = {
  key: 1,
  class: "bet-group h-direction"
}
const _hoisted_4 = { class: "toast-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bet_box = _resolveComponent("bet-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.gameType === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_bet_box, {
              class: "bet-box",
              color: 'blue',
              label: _ctx.t('baccarat.playerpair'),
              betType: 'PLAYER_PAIR',
              allowBet: _ctx.allowBet,
              tempBet: _ctx.bluePairTemp,
              confirmedBet: _ctx.bluePairFinal,
              isWinner: _ctx.winresult.indexOf(8) !== -1,
              onPlaceBet: _ctx.onPlaceBet
            }, null, 8, ["label", "allowBet", "tempBet", "confirmedBet", "isWinner", "onPlaceBet"]),
            _createVNode(_component_bet_box, {
              class: "bet-box",
              color: 'green',
              label: _ctx.t('baccarat.tiebox'),
              betType: 'TIE',
              allowBet: _ctx.allowBet,
              tempBet: _ctx.greenTemp,
              confirmedBet: _ctx.greenFinal,
              isWinner: _ctx.winresult.indexOf(5) !== -1,
              onPlaceBet: _ctx.onPlaceBet
            }, null, 8, ["label", "allowBet", "tempBet", "confirmedBet", "isWinner", "onPlaceBet"]),
            _createVNode(_component_bet_box, {
              class: "bet-box",
              color: 'red',
              label: _ctx.t('baccarat.bankerpair'),
              betType: 'BANKER_PAIR',
              allowBet: _ctx.allowBet,
              tempBet: _ctx.redPairTemp,
              confirmedBet: _ctx.redPairFinal,
              isWinner: _ctx.winresult.indexOf(7) !== -1,
              onPlaceBet: _ctx.onPlaceBet
            }, null, 8, ["label", "allowBet", "tempBet", "confirmedBet", "isWinner", "onPlaceBet"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_bet_box, {
              class: "bet-box",
              color: 'blue',
              label: _ctx.t('baccarat.player'),
              betType: 'PLAYER',
              allowBet: _ctx.allowBet,
              tempBet: _ctx.blueTemp,
              confirmedBet: _ctx.blueFinal,
              isWinner: _ctx.winresult.indexOf(3) !== -1,
              onPlaceBet: _ctx.onPlaceBet
            }, null, 8, ["label", "allowBet", "tempBet", "confirmedBet", "isWinner", "onPlaceBet"]),
            _createVNode(_component_bet_box, {
              class: "bet-box",
              color: 'red',
              label: _ctx.t('baccarat.banker'),
              betType: 'BANKER',
              allowBet: _ctx.allowBet,
              tempBet: _ctx.redTemp,
              confirmedBet: _ctx.redFinal,
              isWinner: _ctx.winresult.indexOf(1) !== -1,
              onPlaceBet: _ctx.onPlaceBet
            }, null, 8, ["label", "allowBet", "tempBet", "confirmedBet", "isWinner", "onPlaceBet"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.gameType === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_bet_box, {
            class: "bet-box",
            color: 'red',
            label: _ctx.t('dragontiger.dragon'),
            betType: 'DRAGON',
            allowBet: _ctx.allowBet,
            tempBet: _ctx.redTemp,
            confirmedBet: _ctx.redFinal,
            isWinner: _ctx.winresult.indexOf(4) !== -1,
            onPlaceBet: _ctx.onPlaceBet
          }, null, 8, ["label", "allowBet", "tempBet", "confirmedBet", "isWinner", "onPlaceBet"]),
          _createVNode(_component_bet_box, {
            class: "bet-box",
            color: 'green',
            label: _ctx.t('baccarat.tiebox'),
            betType: 'DT_TIE',
            allowBet: _ctx.allowBet,
            tempBet: _ctx.greenTemp,
            confirmedBet: _ctx.greenFinal,
            isWinner: _ctx.winresult.indexOf(5) !== -1,
            onPlaceBet: _ctx.onPlaceBet
          }, null, 8, ["label", "allowBet", "tempBet", "confirmedBet", "isWinner", "onPlaceBet"]),
          _createVNode(_component_bet_box, {
            class: "bet-box",
            color: 'blue',
            label: _ctx.t('dragontiger.tiger'),
            betType: 'TIGER',
            allowBet: _ctx.allowBet,
            tempBet: _ctx.blueTemp,
            confirmedBet: _ctx.blueFinal,
            isWinner: _ctx.winresult.indexOf(6) !== -1,
            onPlaceBet: _ctx.onPlaceBet
          }, null, 8, ["label", "allowBet", "tempBet", "confirmedBet", "isWinner", "onPlaceBet"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_TransitionGroup, {
        class: "toast-container",
        tag: "div",
        name: "list",
        appear: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toastMessage, (toast, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "toast",
              key: index
            }, [
              _createElementVNode("span", null, _toDisplayString(toast.message), 1)
            ]))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}