
import { iconBig, iconSmall, iconCockroach } from '@/components/icon'
import { PropType, computed, defineComponent } from 'vue'
import { RoadMapSummaryData } from '../roadmap/datamodel/types'
import { RoadMapData } from '../roadmap/datamodel/RoadMapData'

export default defineComponent({
    components: {
        iconBig,
        iconSmall,
        iconCockroach
    },
    props: {
        label: {
            type: String as PropType<string>,
            default: () => ''
        },
        color: {
            type: String as PropType<'red' | 'blue' | ''>,
            default: () => ''
        },
        mapData: {
            type: Object as PropType<RoadMapSummaryData>,
            required: true
        }
    },
    setup(props) {
        const hasNoData = computed(() => {
            if (!props.mapData.data) return true

            const mapData = props.mapData.data as RoadMapData

            return mapData.bigeyes.length <= 0
        })

        const hasSmall = computed(() => {
            if (!props.mapData.data) return true

            const mapData = props.mapData.data as RoadMapData

            return mapData.smalls.length <= 0
        })

        const hasCockroach = computed(() => {
            if (!props.mapData.data) return true

            const mapData = props.mapData.data as RoadMapData

            return mapData.cockroachs.length <= 0
        })

        const bigeyeColor = computed(() => {
            if (!props.mapData.data) return ''
            const mapData = props.mapData.data as RoadMapData

            if (mapData.bigeyes.length <= 0) return ''

            return mapData.bigeyes[mapData.bigeyes.length - 1].type === 0
                ? '#c70004'
                : '#0000fe'
        })

        const smallColor = computed(() => {
            if (!props.mapData.data) return ''
            const mapData = props.mapData.data as RoadMapData

            if (mapData.smalls.length <= 0) return ''

            return mapData.smalls[mapData.smalls.length - 1].type === 0
                ? '#c70004'
                : '#0000fe'
        })

        const cocroachColor = computed(() => {
            if (!props.mapData.data) return ''
            const mapData = props.mapData.data as RoadMapData

            if (mapData.cockroachs.length <= 0) return ''

            return mapData.cockroachs[mapData.cockroachs.length - 1].type === 0
                ? '#c70004'
                : '#0000fe'
        })

        return {
            hasNoData,
            hasSmall,
            hasCockroach,
            bigeyeColor,
            smallColor,
            cocroachColor
        }
    }
})
