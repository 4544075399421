import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c52f205"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "box" }
const _hoisted_4 = { class: "box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_iconBig = _resolveComponent("iconBig")!
  const _component_iconSmall = _resolveComponent("iconSmall")!
  const _component_iconCockroach = _resolveComponent("iconCockroach")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["prediction-root", _ctx.color])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.hasNoData)
        ? (_openBlock(), _createBlock(_component_iconBig, {
            key: 0,
            color: _ctx.bigeyeColor
          }, null, 8, ["color"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.hasSmall)
        ? (_openBlock(), _createBlock(_component_iconSmall, {
            key: 0,
            color: _ctx.smallColor
          }, null, 8, ["color"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.hasCockroach)
        ? (_openBlock(), _createBlock(_component_iconCockroach, {
            key: 0,
            color: _ctx.cocroachColor
          }, null, 8, ["color"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}