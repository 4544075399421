
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { EventBuss } from '@/types/global'
import {
    Emitter,
    computed,
    defineComponent,
    inject,
    onBeforeMount,
    ref
} from 'vue'
import { iconSetting } from '@/components/icon'
import { useStore } from '@/store'
import { abbreviateNumber } from '@/utils'
import { AppMutation } from '@/store/types'
import { useI18n } from 'vue-i18n'
export default defineComponent({
    components: {
        'icon-setting': iconSetting
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const { t } = useI18n()

        const activeChip = ref(0)

        onBeforeMount(() => {
            activeChip.value = currentChipIndex.value
        })

        const selectChips = (chipIndex: number) => {
            activeChip.value = chipIndex
            store.commit(AppMutation.SET_SELECTED_CHIP, chipIndex)
        }

        const selectCustomChip = () => {
            if (customChipValue.value > 0) {
                activeChip.value = 5

                store.commit(AppMutation.SET_SELECTED_CHIP, 5)
                return
            }

            showChipsDialog()
        }

        const toShortValue = (v: number) => {
            return abbreviateNumber(v)
        }

        const showChipsDialog = () => {
            const _event: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'edit-custom-chip'
            }
            emitter.emit(EventBuss.DIALOG, _event)
        }

        const customChipValue = computed(
            (): number => store.getters['customChipValue']
        )

        const chips = computed((): number[] => store.getters['chips'])
        const currentChipIndex = computed(
            (): number => store.getters['currentChipIndex']
        )

        return {
            chips,
            activeChip,
            customChipValue,
            selectCustomChip,
            showChipsDialog,
            toShortValue,
            selectChips,
            t
        }
    }
})
